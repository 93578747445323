import { defineStore } from 'pinia';
import {
  create as apiCreate,
  get as apiGet,
  update as apiUpdate,
  del as apiDelete,
  getQrCode as apiGetQrCode,
  getAssets as apiGetAssets,
  updatePositionSubElements as apiUpdatePositionSubElements,
  unsubscribeEmail as apiUnsubscribeEmail
} from '@/api/endpoints/shareAsset';
import { useAuthStore } from '@/stores/auth';
import { SHARE_ASSET_TYPE } from '@/utils/types';
import { useAppStore } from './app';

const initialState = {
  current: null,
  medias: []
};

export const key = SHARE_ASSET_TYPE;
export const useShareAssetStore = defineStore(key, {
  state: () => ({ ...initialState }),
  getters: {
    paidNamedWebLink: () => !!useAuthStore().features?.paidNamedWebLink
  },
  actions: {
    async reset() {
      Object.assign(this, initialState);
    },
    async get(id) {
      const gettingNewItem = this.current?.id !== id;
      if (gettingNewItem) await this.reset();

      this.current = await apiGet(id);
      if (gettingNewItem) await this.getAssets();

      return this.current;
    },
    async modify({ id, ...data }) {
      const item = await (id ? apiUpdate(id, data) : apiCreate(data));
      return item;
    },
    async delete(id) {
      try {
        await apiDelete(id);
      } finally {
        await this.itemDeleted({ id });
      }
    },
    async updatePositionSubElements({ media, shareAssetLocation, newIndex }) {
      if (!this.current) return;

      const startMedias = this.medias.filter(
        (x) => x.shareAssetLocation === 'start'
      );
      const endMedias = this.medias.filter(
        (x) => x.shareAssetLocation === 'end'
      );

      const data = {
        positions: []
      };

      const medias = shareAssetLocation === 'start' ? startMedias : endMedias;

      const index = medias.findIndex((x) => x.id === media.id);
      if (index === -1) return;
      const item = medias[index];

      medias.splice(index, 1);
      medias.splice(newIndex, 0, item);

      let i = 1;
      medias.forEach((media) => {
        if (i !== media.position) {
          data.positions.push({ id: media.id, position: i });
        }

        media.position = i;
        i++;
      });

      await apiUpdatePositionSubElements(this.current.id, data);
      this.medias = [...startMedias, ...endMedias];
    },
    async currentExists({ id }) {
      return this.current?.id === id;
    },
    async getQrCode(id) {
      return apiGetQrCode(id);
    },
    async getAssets(params) {
      if (!this.current) return;
      if (params?.id && this.current.id !== params.id) return;

      const items = await apiGetAssets(this.current.id);
      this.medias = items;

      return { items };
    },
    async clearAssets() {
      this.medias = [];
    },
    async clearCurrent() {
      this.current = null;
    },
    async assetExists(options = {}) {
      if (!this.current) return false;

      const { id } = options;
      return (
        this.current.assetId === id || this.medias.some((x) => x.id === id)
      );
    },
    async assetUpdated(item) {
      if (!this.current || !item) return;

      if (this.current.assetId === item.id) {
        this.current = {
          ...this.current,
          asset: { ...this.current.asset, name: item.name }
        };
      } else if (this.medias.some((x) => x.id === item.id)) {
        this.medias = this.medias.map((x) =>
          x.id === item.id ? { ...item } : x
        );
      }
    },
    async assetDeleted({ id }) {
      if (this.medias.some((x) => x.id === id)) {
        this.medias = this.medias.filter((x) => x.id !== id);
      }
    },
    async itemUpdated({ id }) {
      if (this.current?.id === id) await this.get(id);
    },
    async itemDeleted({ id }) {
      if (this.current?.id === id)
        await useAppStore().setNavigateTo({ name: 'root' });
    },
    async unsubscribeEmail(token) {
      return apiUnsubscribeEmail(token);
    }
  }
});

export default {
  key,
  use: useShareAssetStore
};
